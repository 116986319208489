import styled from 'styled-components';
import { Container } from 'src/components/Container';
import { motion } from 'framer-motion';

//hero styles
export const Hero = styled.section`
  padding-block: 60px;
  background-color: ${props => props.theme.colors.brand.dark};
  color: ${props => props.theme.colors.gray[400]};

  @media (max-width: 579px) {
    padding-block: 48px;
  }
`;

export const HeroContainer = styled(Container)`
  display: grid;
  place-content: center;
  justify-items: center;

  button {
    position: relative;
    z-index: 2;
  }
`;

export const TextGroup = styled(motion.div)`
  max-width: 898px;
  margin-inline: auto;
  text-align: center;
  margin-bottom: ${props => props.theme.spacing[5]};
`;

// old design
// export const HeroHeading = styled(motion.h1)`
//   font-weight: 800;
//   line-height: 80px;
//   font-size: ${(props) => props.theme.sizes.heading1};
//   margin-bottom: ${(props) => props.theme.spacing[3]};

//   @media (max-width: 767px) {
//     font-size: ${(props) => props.theme.sizes.heading3};
//     line-height: 60px;
//   }

//   @media (max-width: 579px) {
//     font-size: ${(props) => props.theme.sizes.heading4};
//     line-height: 52px;
//   }
// `;
export const HeroHeading = styled(motion.h1)`
  font-weight: 800;
  line-height: 100px;
  font-size: ${props => props.theme.sizes.heading1};
  margin-bottom: ${props => props.theme.spacing[3]};

  @media (max-width: 767px) {
    font-size: ${props => props.theme.sizes.heading3};
    line-height: 60px;
  }

  @media (max-width: 579px) {
    font-size: ${props => props.theme.sizes.heading4};
    line-height: 52px;
  }
`;

// old design
// export const HeroText = styled(motion.p)`
//   max-width: 840px;
//   font-size: ${(props) => props.theme.sizes.lg};
//   line-height: 32px;
// `;
export const HeroText = styled(motion.p)`
  max-width: 840px;
  // font-size: ${props => props.theme.sizes.lg}; /* old design */
  font-size: ${props => props.theme.sizes.xlg}; /* old design */

  line-height: 42px;
  font-weight: 500;
`;

// old design
// export const HeroImages = styled(motion.div)`
//   display: grid;
//   width: 100%;
//   grid-template-columns: repeat(3, 1fr);
//   gap: 30px;
//   margin-top: ${(props) => props.theme.spacing[7]};
//   @media (max-width: 767px) {
//     grid-template-columns: unset;
//     grid-template-areas:
//       "hero1 hero1"
//       "hero2 hero3";
//   }

//   @media (max-width: 579px) {
//     grid-template-columns: 1fr;
//     grid-template-areas: unset;
//   }
// `;

export const HeroImages = styled(motion.div)`
  justify-content: center;
  align-items: center;
  display: flex;

  &:focus-visible,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const HeroImagesLabel = styled(motion.div)`
  background-color: rgba(245, 245, 245, 0.7);
  position: absolute;
  padding: 20px 30px;
  border-radius: 5px;
  z-index: 1000;
  bottom: 10%;
  margin-left: 10px;
  width: 250px;
`;

export const HeroImagesLabelWrapper = styled(motion.div)`
  position: ralative;
`;

export const HeroImage = styled(motion.img)`
  width: 100%;
  height: 320px;
  object-fit: cover;
  border-radius: ${props => props.theme.radius.xl};

  @media (max-width: 767px) {
    height: 280px;

    &:first-child {
      grid-area: hero1;
    }
    &:nth-child(2) {
      grid-area: hero2;
    }
    &:last-child {
      grid-area: hero3;
    }

    @media (max-width: 579px) {
      grid-area: unset !important;
    }
  }
`;
//portfolio styles
export const PortfolioContainer = styled.section`
  padding-block: 85px;

  @media (max-width: 767px) {
    padding-block: 50px;
  }
`;
export const PortfolioCardsWrapper = styled.div`
  width: 85%;
  margin-inline: auto;
  margin-block: 1rem;

  @media (min-width: 768px) {
    margin-block: 3rem 2rem;
  }

  display: grid;
  gap: 1rem;

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
export const Card = styled.div`
  width: 20%;
  height: 400px;
  border-radius: 5px;
`;

//services styles
export const Services = styled.section`
  padding-bottom: 85px;

  @media (max-width: 767px) {
    padding-bottom: 50px;
  }
`;

export const ServicesText = styled(Container)`
  display: flex;
  column-gap: 70px;
  align-items: center;
  justify-content: space-between;
  place-items: center;
  text-align: center;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
`;

export const PortfolioText = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
`;

export const ServiceHeadingGroup = styled(motion.div)`
  display: grid;
  width: 100%;
  gap: 16px;
  place-items: center;
  text-align: center;
  max-width: 790px;
  margin: 0 auto;
  color: #36454f;
`;

export const ServiceHeading = styled.h2`
  font-family: Larsseit;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #36454f;
`;

export const PortfolioHeading = styled.h2`
  font-family: Larsseit;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  color: #36454f;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;
export const ServiceSubHeading = styled.h3`
  font-size: ${props => props.theme.sizes.heading4};
  font-weight: 800;
  line-height: 48px;
  // max-width: 600px;
`;

export const ServicesIntro = styled(motion.p)`
  font-size: ${props => props.theme.sizes.lg};
  line-height: 32px;
  max-width: 460px;
`;

export const ServiceCards = styled(Container)`
  border-radius: ${props => props.theme.radius.xl};
  overflow: hidden;
  margin-top: 56px;
  display: flex;
`;

export const ServiceBanner = styled(Container)`
  margin-inline: auto;
  padding-top: 140px;
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    padding-top: 80px;
  }
`;

export const ServiceBannerImg = styled.img`
  width: 100%;
  height: 480px;
  object-fit: cover;
  border-radius: 64px 0 0 64px;

  @media (max-width: 767px) {
    border-radius: 32px 32px 0 0;
    height: 350px;
  }
`;

export const ServiceBannerText = styled.div`
  background-color: ${props => props.theme.colors.brand.accent};
  height: 90%;
  margin-block: auto;
  border-radius: 0 64px 64px 0;
  color: ${props => props.theme.colors.white};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${props => props.theme.spacing[7]};

  @media (max-width: 767px) {
    border-radius: 0 0 32px 32px;
    height: fit-content;
    gap: ${props => props.theme.spacing[5]};
    padding: 2rem 2rem 4rem;
  }
`;

export const ServiceBannerHeading = styled(motion.h3)`
  font-size: ${props => props.theme.sizes.heading5};
  font-weight: 700;
  line-height: 48px;

  @media (max-width: 579px) {
    font-size: ${props => props.theme.sizes.heading6};
  }
`;

export const ServiceBannerList = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing[5]};
`;

export const ListItem = styled(motion.li)`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: ${props => props.theme.sizes.lg};
`;

//testimonials styles
export const Testimonials = styled.section`
  background-color: ${props => props.theme.colors.lightBlue[200]};
  padding-block: 140px;

  @media (max-width: 767px) {
    padding-block: 80px;
  }
`;

export const TestimonialGrid = styled(Container)`
  position: relative;
`;

export const TestimonialSlider = styled.div`
  width: 65%;
  margin-inline: auto;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const AvatarGroup = styled(motion.div)`
  display: grid;
  gap: 64px;
  position: absolute;
  z-index: 2;
  top: 10%;
  left: 0;

  &:last-child {
    left: unset;
    right: 0;
  }

  @media (max-width: 800px) {
    all: unset;
    display: flex;
    align-items: center;

    &:last-child {
      display: flex;
      /* flex-direction: row-reverse; */
      justify-content: flex-end;
    }
  }
`;

export const AvatarImage = styled(motion.img)`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  position: relative;

  &[data-left='true'] {
    &:first-child,
    &:last-child {
      left: -40px;
    }
    &:nth-child(2) {
      right: -40px;
    }
  }

  &[data-right='true'] {
    &:first-child,
    &:last-child {
      right: -40px;
    }

    &:nth-child(2) {
      left: -40px;
    }
  }

  &:last-child {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 800px) {
    position: static;
    margin-left: -1.2rem;
    border: 4px solid ${props => props.theme.colors.lightBlue[200]};
  }
`;

export const PartnerBrandsSection = styled.section`
  padding-top: 2rem;
  display: grid;
  gap: 1rem;
`;

export const PartnerBrandsWrapper = styled(Container)`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  padding:0px 110px;

  img {
    width: clamp(33px, calc(33vw / 2), 200px);
  }
`;
